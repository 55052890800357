@import "variables";
$primary:#f3f3f3;
body{
	background:$primary;
	position: relative;
	#application-div{

		display: none;
		#application-form{
			#childs_ethnicity_alt_div{
				display: none;
			}

			#extra-parent{
				display: none;
			}
			.siblings-row{
				display: none;
			}
			#address-select-div,#change-postcode-div{
				display: none;
			}
			#find-address,#change-postcode{
				margin-top:25px;
			}
			#baptism_wrapper{
				display:none;
			}
		}
		.wizard{
			.steps{
				ul {
					li {
		    			width: 16.66%;
		    		}
		    	}
		    }
		}
	}
	#help-panel{
		position: absolute;
		width: 33%;
		height: 100vh;
		top:0;
		right:0;
		background:$dark-gray;
		z-index:10;
		padding: 20px;
		display: none;
		z-index:1000;
		border: 1px solid transparent;
	    -webkit-box-shadow: 0 3px 7px 0 #a8a8a8;
	    -moz-box-shadow: 0 3px 7px 0 #a8a8a8;
	    box-shadow: 0 3px 7px 0 #a8a8a8;
		#help-inner{
			background:$gray;
			padding:10px;
			height: 100%;
		}
		#close-help{
			cursor:pointer;
		}

	}
}
